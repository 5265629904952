import React from 'react';
import { Divider, Header } from 'semantic-ui-react';

const ConsumeHeader = () => {
  return <>
    <Header
      textAlign='center'
      as='h2'
      image={process.env.REACT_APP_ORG_LOGO_URI}
      content={process.env.REACT_APP_ORG_NAME}
      // color='grey'
    />
    <Divider />
  </>
}

export default ConsumeHeader