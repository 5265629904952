import { Router, RouteComponentProps, Redirect } from '@reach/router';
import React from 'react';
import { Container, Divider, Icon } from 'semantic-ui-react';
import './App.css';
import Dashboard from './Dashboard';
import FileCloud from './FileCloud';
import FileCloudConsume from './FileCloudConsume';
import Navigation from './Navigation';
import TissueBank from './TissueBank';
import Registry from './Registry';
import RegistryConsume from './RegistryConsume';
import ConsumeHeader from './ConsumeHeader';
// import logo from './logo.svg';
/* <img src={logo} className="App-logo" alt="logo" /> */

function App() {
  return <>
    <Router>
      <Redirect from='/' to='dashboard' noThrow />
      <AppDashboard path='dashboard'>
        <Dashboard path='/' />
        <FileCloud path='filecloud/*' />
        <Registry path='registry/*' />
        <TissueBank path='tissuebank' />
      </AppDashboard>
      <AppConsume path='app'>
        <FileCloudConsume path='filecloud/*' />
        <RegistryConsume path='registry/*' />
      </AppConsume>
    </Router>
  </>
}

const AppDashboard: React.FunctionComponent<RouteComponentProps> = ({ children }) => {
  return <>
    <Container>
      <Navigation />
      {children}
      <Footer />
    </Container>
  </>
}

const AppConsume: React.FunctionComponent<RouteComponentProps> = ({ children }) => {
  return <>
    <Container text >
      <ConsumeHeader />
      {children}
      <Footer />
    </Container>
  </>
}

const Footer: React.FunctionComponent = () => {
  return <>
    <Divider style={{ marginTop: '3rem' }} />
    <div style={{ textAlign: 'center', color: '#ccc' }}>
      Rarebase, PBC &copy; 2020<br />
      Rarebase <Icon name='heart' />
      &nbsp;
      {/* NF2 Biosolutions */}
      {process.env.REACT_APP_ORG_NAME}
    </div>
  </>
}

export default App;
