import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import { Header, Icon, Item, Menu, List, Message, Progress, Segment, Divider } from 'semantic-ui-react';
import moment from 'moment'

interface Props extends RouteComponentProps {
  section?: string
}

const TissueBank: React.FunctionComponent<Props> = ({ section }) => {
  return <>
    <Header
      size='huge'
      content='Tissue Bank'
      icon='snowflake'
      color='purple'
    />
    <Menu tabular >
      <Menu.Item
        as={Link}
        to='/tissuebank'
        content='All Cell Lines'
        active={!section}
      />
      <Menu.Item
        link
        content='Tissues &amp; Samples'
      />
    </Menu>
    <TissueBankSampleList />
  </>
}

const TissueBankSampleList = () => {
  return <>
    <Menu>
      <Menu.Item
        content='New Sample Request'
        icon='plus'
        link
      />
    </Menu>
    <Message info>
      You currently have <strong>2</strong> cell lines in active development.
    </Message>
    <Item.Group>
      <TissueBankSampleItem
        name='IPS Cell Line X'
        progress={3}
      />
      &nbsp;
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='hourglass' />
          In Development
        </Header>
      </Divider>
      <TissueBankSampleItem
        name='Cell Line Y'
        progress={0}
      />
      <TissueBankSampleItem
        name='Cell Line Z'
        progress={2}
      />
    </Item.Group>
  </>
}

interface TissueBankSampleItemProps {
  name: string
  progress: number
}

const TissueBankSampleItem: React.FunctionComponent<TissueBankSampleItemProps> = ({
  name, progress
}) => {
  type CellLineStep = {
    success: string
    progress: string
  }
  const steps: CellLineStep[] = [
    {
      success: 'Tissue Collected',
      progress: 'Waiting for Tissue Collection',
    },
    {
      success: 'Consent Signed',
      progress: 'Waiting for Consent',
    },
    {
      success: 'Cultured Cells',
      progress: 'Waiting for Cell Culturing',
    },
  ]
  return <>
    <Segment><Item.Group><Item>
      <Item.Content>
        <Item.Header>
          {name}
        </Item.Header>
        <Item.Meta>
          <Icon
            name={progress >= steps.length ? 'check' : 'hourglass'}
            color={progress >= steps.length ? 'green' : undefined}
          />
          &nbsp;
          {progress >= steps.length ? 'Frozen, sample available' : 'Cell line culturing in progress'}
        </Item.Meta>
        <Item.Description>
          {progress < steps.length ? <List>
            {steps.map((step, index) => <List.Item key={index}>
              <Icon
                name={index < progress ? 'check' : 'triangle right'}
                color={index < progress ? 'green' : 'grey'}
              />
              <List.Content>
                <List.Description
                  style={{
                    textDecoration: index < progress ? 'line-through' : undefined
                  }}
                >
                  {index < progress ? step.success : step.progress}
                  &nbsp;
                  {index < progress ? moment().subtract(Math.floor(Math.random() * 10), 'weeks').fromNow() : undefined}
                </List.Description>
              </List.Content>
            </List.Item>)}
          </List> : undefined}
        </Item.Description>
        <Item.Extra>
          {progress < steps.length ? <Progress
            percent={(progress / steps.length) * 100} size='tiny' color='blue'
          /> : undefined}
          [Purpose]
        </Item.Extra>
      </Item.Content>
    </Item></Item.Group></Segment>
  </>
}

export default TissueBank