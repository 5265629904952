import { Link, Redirect, RouteComponentProps, Router, WindowLocation } from '@reach/router';
import React, { useState } from 'react';
import { Button, Checkbox, Divider, Flag, FlagNameValues, Form, Header, Icon, IconProps, Label, Menu, Message, Progress, Segment, Statistic, Table, TextArea } from 'semantic-ui-react';

interface Props extends RouteComponentProps {
  location?: WindowLocation
}

const Registry: React.FunctionComponent<Props> = ({ location }) => {
  const section = location?.pathname.split('/').reverse()[0]
  return <>
    <Header
      size='huge'
      content='Registry'
      icon='users'
      color='blue'
    />
    <Menu tabular >
      <Menu.Item
        as={Link}
        to='overview'
        content='Overview'
        active={section === 'overview'}
      />
      <Menu.Item
        as={Link}
        to='list'
        icon='table'
        content='User List'
        active={section === 'list'}
      />
      <Menu.Item
        as={Link}
        to='message'
        icon='paper plane'
        content='Share Message'
        active={section === 'message'}
      />
      <Menu.Item
        as={Link}
        to='/app/registry'
        content='App Demo'
        icon='external'
      />
    </Menu>
    <Router>
      <Redirect from='/' to='overview' noThrow />
      <RegistryOverview path='overview' />
      <RegistryList path='list' />
      <RegistryMessage path='message' />

    </Router>
  </>
}

interface RegistryOverviewProps extends RouteComponentProps {
}

const RegistryOverview: React.FunctionComponent<RegistryOverviewProps> = () => {
  return <>
    <Message success>
      <p>This week <strong>2</strong> new participants signed up</p>
      <Button color='green' content={`Check Who's New`} size='tiny' />
    </Message>
    <Message info>
      <Header><strong>28</strong> participants</Header>
      <p>Your current goal is set to <strong>50</strong> participants</p>
      <strong>2</strong><Icon name='caret up' /> in the last 7 days
      <Progress percent={100 * 28 / 50} size='tiny' color='blue' />
      <Button size='tiny' content='Update Goal' />
    </Message>
    {/* <Segment>
      <Header>Message Stats</Header>
      <Statistic size='small' color='red'>
        <Statistic.Value>27</Statistic.Value>
        <Statistic.Label>prior</Statistic.Label>
      </Statistic>
      <Statistic size='small' color='orange'>
        <Statistic.Value>8'</Statistic.Value>
        <Statistic.Label>orange</Statistic.Label>
      </Statistic>
      <Statistic size='small' color='yellow'>
        <Statistic.Value>28</Statistic.Value>
        <Statistic.Label>yellow</Statistic.Label>
      </Statistic>
    </Segment> */}
    <Segment>
      <Header>Domain Name</Header>
      <p>Your registry is currently available at <a href={`https://registry.${process.env.REACT_APP_ORG_DOMAIN}`}>{`https://registry.${process.env.REACT_APP_ORG_DOMAIN}`}</a></p>
      <Button size='tiny' content='Set Alternative Domain' />
    </Segment>
    <Segment>
      <Form>
        <Header>Settings</Header>
        <Header size='small'>Contact Options</Header>
        <Form.Field><Checkbox toggle label='Enable Email' checked /></Form.Field>
        <Form.Field><Checkbox toggle label='Enable SMS' checked /></Form.Field>
        <Header size='small'>Disorders and Subtypes</Header>
        <Form.Field><Checkbox toggle label={process.env.REACT_APP_DISORDER_NAME} checked /></Form.Field>
        <Header size='small'>Login Options</Header>
        <Form.Field><Checkbox toggle label='Email' checked /></Form.Field>
        <Form.Field><Checkbox toggle label='Facebook' checked /></Form.Field>
      </Form>
    </Segment>
  </>
}

interface RegistryListProps extends RouteComponentProps { }

const RegistryList: React.FunctionComponent<RegistryListProps> = () => {
  return <>
    <Menu>
      <Menu.Item
        content='Download'
        icon='download'
        link
      />
    </Menu>
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Location</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <RegistryListItem
          name='Onno Faber'
          email='onno@onnofaber.com'
          phone='+14156885181'
          role='patient'
          country='us'
        />
        <RegistryListItem
          name='John Appleseed'
          email='john@appleseed.com'
          role='parent'
          country='us'
        />
        <RegistryListItem
          name='James Bond'
          email='james@bond.com'
          phone='+44007007007'
          role='patient'
          country='uk'
        />
      </Table.Body>
    </Table>
  </>
}

interface RegistryListItemProps {
  name: string
  email?: string
  phone?: string
  role: 'patient' | 'parent'
  country: FlagNameValues
}

const RegistryListItem: React.FunctionComponent<RegistryListItemProps> = ({
  name, email, phone, role, country
}) => {
  return <Table.Row>
    <Table.Cell>{name}</Table.Cell>
    <Table.Cell><a href={`mailto:${email}`}>{email}</a></Table.Cell>
    <Table.Cell>{phone}</Table.Cell>
    <Table.Cell><Label content={role} style={{ textTransform: 'capitalize' }} /></Table.Cell>
    <Table.Cell style={{ textTransform: 'uppercase' }}><Flag name={country} />{country}</Table.Cell>
  </Table.Row>
}

interface RegistryMessageProps extends RouteComponentProps {
}

const RegistryMessage: React.FunctionComponent<RegistryMessageProps> = () => {
  const [priority, setPriority] = useState<number>(0)
  return <>
    <Menu>
      <Menu.Item
        content='Compose New'
        icon='pencil'
        link
      />
      <Menu.Item
        content='History'
        icon='time'
        link
      />
    </Menu>
    <Message>
      <p>Compose a message below and specify priority. Keep in mind users in the registry can adjust there contact preferences, so use priority messages wisely.</p>
    </Message>
    <Message>
      <Header>
        <Icon
          name='flag'
          color={['blue', 'yellow', 'red'][priority] as IconProps['color']}
        />
        Priority: {['Normal', 'Medium', 'High'][priority]}
      </Header>
      <Statistic.Group
        size='tiny'
        color={['blue', 'yellow', 'red'][priority] as IconProps['color']}
        items={[
          { key: 'email', label: 'Email', value: [15, 20, 22][priority] },
          { key: 'sms', label: 'SMS', value: [3, 5, 9][priority] },
        ]}
        style={{ paddingBottom: 0 }}
      />
      <p>
        {[
          'This message will be sent as a general update',
          'This message will be sent as a important news',
          'This message will be sent as a high priority information',
        ][priority]}
        &nbsp;and automatically respect users contact preferences.
      </p>
    </Message>
    <Segment>
      <Form>
        <Form.Group grouped>
          <label>Priority</label>
          <Form.Radio
            name='priority'
            label='Normal (general news and updates)'
            value={0}
            onChange={() => setPriority(0)}
            checked={priority === 0}
          />
          <Form.Radio
            name='priority'
            label='Medium (important news and updates)'
            value={1}
            onChange={() => setPriority(1)}
            checked={priority === 1}
          />
          <Form.Radio
            name='priority'
            label='High (clinical trial recruitment)'
            value={2}
            onChange={() => setPriority(2)}
            checked={priority === 2}
          />
        </Form.Group>
        <Divider horizontal>
          <Header><Icon name='envelope' color='blue' />Email</Header>
        </Divider>
        <Form.Field>
          <label>Subject</label>
          <input placeholder='Subject' />
        </Form.Field>
        <Form.Field>
          <label>Header Image</label>
          <input type='file' />
        </Form.Field>
        <Form.Field>
          <label>Message</label>
          <TextArea rows={10} placeholder='Message' />
        </Form.Field>
        <Divider horizontal>
          <Header><Icon name='chat' color='green' />SMS</Header>
        </Divider>
        <Form.Field>
          <label>SMS Message</label>
          <TextArea rows={2} placeholder='SMS Message' />
          <p>Max 200 characters</p>
        </Form.Field>
        <Message info>
          <p>In the next screen you will be able to review your message, which will include your organization's logo and layout.</p>
          <p>Based on user's preferences will will automatically select who will receive emails and/or SMS messages.</p>
        </Message>
        <Button type='submit' primary>Review</Button>
      </Form>
    </Segment>
  </>
}

export default Registry