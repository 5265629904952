import { Link } from '@reach/router';
import React from 'react';
import { Button, Card, Icon, IconProps } from 'semantic-ui-react';

type Props = {
  name?: string
  icon: IconProps['name']
  color: IconProps['color']
  label: string
  description: string
  countLabel: string
  count: number
}

const DashboardItem = ({ name, color, icon, label, description, count, countLabel }: Props) => {
  return (
    <Card>
      <div style={{ padding: '0.5rem 0', textAlign: 'center' }}>
        <Icon name={icon} size='huge' fitted color={color} />
      </div>
      <Card.Content>
        <Card.Header>
          {label}
        </Card.Header>
        <Card.Meta>
          {count} {countLabel}
        </Card.Meta>
        <Card.Description>
          {description}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button
          as={Link}
          to={name || ''}
          fluid
          basic
          primary={!!name}
          disabled={!name}
          content={name ? 'Open' : 'Coming Soon'}
        />
      </Card.Content>
    </Card>
  )
}

export default DashboardItem