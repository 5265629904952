import { Link, Redirect, RouteComponentProps, Router, WindowLocation } from '@reach/router';
import moment from 'moment';
import React from 'react';
import { Button, Header, Icon, IconProps, Item, Label, Menu, Message } from 'semantic-ui-react';

interface Props extends RouteComponentProps {
  location?: WindowLocation
}

const FileCloud: React.FunctionComponent<Props> = ({ location }) => {
  const section = location?.pathname.split('/').reverse()[0]
  return <>
    <Header
      size='huge'
      content='Databank'
      icon='cloud download'
      color='yellow'
    />
    <Menu tabular >
      <Menu.Item
        as={Link}
        to='list'
        content='Files'
        active={section === 'list'}
      />
      <Menu.Item
        as={Link}
        to='requests'
        active={section === 'requests'}
      >
        Download Requests
        <Label circular size='tiny' color='red'>4</Label>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to='/app/filecloud'
        content='App Demo'
        icon='external'
      />
    </Menu>
    <Router>
      <Redirect from='/' to='list' noThrow />
      <FileCloudList path='list' />
      <FileCloudReview path='requests' />
    </Router>
  </>
}

interface FileCloudList extends RouteComponentProps { }

const FileCloudList: React.FunctionComponent<FileCloudList> = () => {
  return <>
    <Menu>
      <Menu.Item
        content='Add File'
        icon='plus'
        link
      />
    </Menu>
    <Message warning>
      <p>You have 4 new download requests to review.</p>
      <Button
        size='tiny'
        color='yellow'
        as={Link}
        to='/filecloud/requests'
        content='Review'
      />
    </Message>
    <Item.Group>
      <FileCloudListItem />
      <FileCloudListItem />
      <FileCloudListItem />
    </Item.Group>
  </>
}

const FileCloudListItem = () => {
  return <>
    <Item>
      <Item.Content>
        <Item.Header>
          Whole Genome Tumor, 90x patient xxx
          </Item.Header>
        <Item.Meta>
          24GB, download time 5 hours
          </Item.Meta>
        <Item.Extra>
          [Readme]
          </Item.Extra>
      </Item.Content>
    </Item>
  </>
}

interface FileCloudReviewProps extends RouteComponentProps { }

const FileCloudReview: React.FunctionComponent<FileCloudReviewProps> = () => {
  return <>
    <Menu>
      <Menu.Item
        content='Download List'
        icon='table'
        link
      />
    </Menu>
    <Item.Group>
      <FileCloudReviewItem
        name='Onno Faber'
        status={0}
      />
      <FileCloudReviewItem
        name='John Appleseed'
        status={1}
      />
      <FileCloudReviewItem
        name='Barack Obama'
        status={2}
      />
    </Item.Group>
  </>
}

interface FileCloudReviewItemProps {
  name: string
  status: number
}

const FileCloudReviewItem: React.FunctionComponent<FileCloudReviewItemProps> = ({
  name, status
}) => {
  const statusIconName: IconProps['name'][] = ['cloud download', 'envelope', 'check']
  const statusIconColor: IconProps['color'][] = [undefined, 'blue', 'green']
  return <>
    <Item.Group>
      <Item>
        <Item.Content>
          <Item.Header
            content={name}
          />
          <Item.Meta>
            <Icon
              name={statusIconName[status]}
              color={statusIconColor[status]}
            />
            &nbsp;
            {status === 0 ? 'Waiting for download approval' : undefined}
            {status === 1 ? `Download links sent ${moment().subtract(1, 'day').fromNow()}, waiting for download` : undefined}
            {status === 2 ? `Downloaded ${moment().subtract(1, 'day').fromNow()}` : undefined}
          </Item.Meta>
          <Item.Description>
            Requested <strong>{Math.floor(Math.random() * 10)}</strong> files {moment().subtract(Math.floor(Math.random() * 10), 'days').fromNow()}
          </Item.Description>
          {status === 0 ? <Item.Extra>
            <Button size='tiny' color='green' icon='check' content='Approve' />
          </Item.Extra> : undefined}
        </Item.Content>
      </Item>
    </Item.Group>
  </>
}

export default FileCloud