import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from '@reach/router';

const Navigation = () => {
  return (
    <Menu fixed='top'>
      <Menu.Item
        as={Link}
        to='/dashboard'
        header>
        {/* <img src='https://rarematter.org/assets/images/image03.png?v63697224514951' /> */}
        {/* <img
          alt=''
          src='https://i1.wp.com/nf2biosolutions.org/wp-content/uploads/2018/08/cropped-favicon-1.png?fit=192%2C192&ssl=1'
        /> */}
        <img
          style={{ marginRight: '0.7rem' }}
          alt={process.env.REACT_APP_ORG_NAME}
          src={process.env.REACT_APP_ORG_LOGO_URI}
        />
        {process.env.REACT_APP_ORG_NAME}
      </Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          link
          icon='cog'
          content='Organization Settings'
        />
        <Menu.Item
          as={Link}
          to='/'
          content='Logout'
        />
      </Menu.Menu>
    </Menu>
  )
}

export default Navigation