import { Link, Redirect, RouteComponentProps, Router } from '@reach/router';
import React, { useState } from 'react';
import { Button, Checkbox, Divider, Form, Grid, Header, Icon, Label, Progress } from 'semantic-ui-react';

interface Props extends RouteComponentProps {
}

const RegistryConsume: React.FunctionComponent<Props> = () => {
  return <Grid centered>
    <Grid.Row>
      <Grid.Column
        // width={8}
      // textAlign='center'
      >
        {/* <Step.Group fluid ordered size='tiny'>
          <Step
            as={Link}
            to='intro'
            title='Sign Up'
            completed
          // active={section === 'select'}
          // completed={!!section && section !== 'select'}
          />
          <Step
            as={Link}
            to='contact'
            title='Contact Preference'
          // active={section === 'select'}
          // completed={!!section && section !== 'select'}
          />
          <Step
            as={Link}
            to='relation'
            title='Involvement'
            active
          // active={section === 'select'}
          // completed={!!section && section !== 'select'}
          />
          <Step
            as={Link}
            to='complete'
            title='Done'
          // active={section === 'select'}
          // completed={!!section && section !== 'select'}
          />
        </Step.Group> */}
        <Router>
          <Redirect from='/' to='intro' noThrow />
          <RegistryConsumeIntro path='intro' />
          <RegistryConsumeContact path='contact' />
          <RegistryConsumeRelation path='relation' />
          <RegistryConsumeComplete path='complete' />
        </Router>
      </Grid.Column>
    </Grid.Row>
  </Grid>
}

const RegistryConsumeIntro: React.FunctionComponent<RouteComponentProps> = () => {
  return <>
    <Header size='medium'>
      <Icon name='quote left' color='blue' />
      If you are dealing with {process.env.REACT_APP_DISORDER_NAME}, please let us know by signing up for the <strong>{process.env.REACT_APP_ORG_NAME} Registry</strong>, it is the easiest way to show your support and help.
    </Header>
    <Header size='medium'>
      Signing up allows us to inform you about important news, updates and clinincal trials. It will also help the community by expading our voice.
    </Header>
    <Divider hidden />
    <Progress
      color='blue'
      size='small'
      active
      percent={55}
    >
      22 signed up from our goal of 40
        </Progress>
    <Divider hidden />
    <div style={{ textAlign: 'center' }}>
      <p>
        <Button
          as={Link}
          to='../contact'
          size='large'
          primary
          content='Sign Up with Facebook'
          icon='facebook'
        />
      </p>
      <p>
        <Button
          as={Link}
          to='../contact'
          size='large'
          content='Sign Up with Email'
          icon='envelope'
        />
      </p>
      <p>Please note, {process.env.REACT_APP_ORG_NAME} respects your privacy and will never share you data with third parties.</p>
    </div>
  </>
}

const RegistryConsumeContact: React.FunctionComponent<RouteComponentProps> = () => {
  const [contactPreferences, setContactPreferences] = useState<{ [key: string]: boolean }>({
    'generalemail': true,
    'generalsms': false,
    'importantemail': true,
    'importantsms': true,
    'criticalemail': true,
    'criticalsms': true,
  })
  const contactOptions: { key: string, label: string }[] = [
    { key: 'general', label: 'General Updates' },
    { key: 'important', label: 'Important Updates' },
    { key: 'critical', label: 'Critical Updates' },
  ]

  const updateContactPreference = (level: string, type: 'email' | 'sms', enabled: boolean) => {
    setContactPreferences({ ...contactPreferences, ...{ [level + type]: enabled } })
  }

  return <>
    <Header size='medium'>
      Contact Preferences
    </Header>
    <Form>
      <Form.Field>
        <label>First Name</label>
        <input placeholder='First Name' />
      </Form.Field>
      <Form.Field>
        <label>Last Name</label>
        <input placeholder='Last Name' />
      </Form.Field>
      <Form.Field>
        <label>Email</label>
        <input type='email' placeholder='Email' />
      </Form.Field>
      <Form.Field>
        <label>Phone</label>
        <input placeholder='Phone' />
      </Form.Field>
      <Form.Field>
        <Checkbox label='I agree to the Terms and Conditions' />
      </Form.Field>
      {contactOptions.map(item => (
        <Form.Field inline key={item.key}>
          <label>{item.label}</label>
          <Label.Group size='tiny'>
            <Label
              as='a'
              icon='envelope'
              content='Email'
              active={contactPreferences[item.key + 'email']}
              color={contactPreferences[item.key + 'email'] ? 'blue' : undefined}
              onClick={() => updateContactPreference(item.key, 'email', !contactPreferences[item.key + 'email'])}
            />
            <Label
              as='a'
              icon='chat'
              content='SMS'
              active={contactPreferences[item.key + 'sms']}
              color={contactPreferences[item.key + 'sms'] ? 'blue' : undefined}
              onClick={() => updateContactPreference(item.key, 'sms', !contactPreferences[item.key + 'sms'])}
            />
          </Label.Group>
        </Form.Field>
      ))}
      <Button as={Link} to='../relation' content='Confirm' primary />
    </Form>
  </>
}

const RegistryConsumeRelation: React.FunctionComponent<RouteComponentProps> = () => {
  return <>
    <Header size='medium'>
      Finally, how are you involved?
    </Header>
    <Form>
      <Form.Group grouped>
        <label>Involvement</label>
        <Form.Radio
          name='involvement'
          label='I am a Patient'
          value={0}
        // onChange={() => setinvolvement(0)}
        // checked={involvement === 0}
        />
        <Form.Radio
          name='involvement'
          label='I am a Parent'
          value={1}
        // onChange={() => setinvolvement(1)}
        // checked={involvement === 1}
        />
        <Form.Radio
          name='involvement'
          label='Other'
          value={2}
        // onChange={() => setinvolvement(2)}
        // checked={involvement === 2}
        />
      </Form.Group>
      <Button as={Link} to='../complete' content='Finish' primary />
    </Form>
  </>
}

const RegistryConsumeComplete: React.FunctionComponent<RouteComponentProps> = () => {
  return <>
    <Header size='medium'>
      <Icon name='check' color='green' />
      Thank You for your participation.
    </Header>
    <p>If you want to help grow the registry, please share this link with other people you know that are dealing with {process.env.REACT_APP_DISORDER_NAME}.</p>
    <Form>
      <input value={`https://registry.${process.env.REACT_APP_ORG_DOMAIN}`} disabled />
    </Form>
    <Divider hidden />
    <p>If you want to help us, please consider sharing our <a href='.'>fundraising campaigns</a>.</p>
  </>
}

export default RegistryConsume