import React from 'react';
import { Card, Header } from 'semantic-ui-react';
import DashboardItem from './DashboardItem';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
}

const Dashboard: React.FunctionComponent<Props> = () => {
  return <>
    <Header textAlign='center' size='huge'>
      Welcome Back
      <Header.Subheader textAlign='center'>
        You currently have 2 active services
      </Header.Subheader> 
    </Header>
    <Card.Group centered>
      <DashboardItem
        name='registry'
        icon='users'
        color='blue'
        label='Contact Registry'
        description='Contact your community by SMS or Email'
        countLabel='participants'
        count={22}
      />
      <DashboardItem
        name='tissuebank'
        icon='snowflake'
        color='purple'
        label='Tissue Bank'
        description='Frozen cell lines and other tissue samples'
        countLabel='types'
        count={4}
      />
      {/* <DashboardItem
        name='assay'
        icon='lab'
        color='purple'
        label='Assays'
        description='Inventory of tests and stuff'
        countLabel='tests'
        count={4}
      /> */}
      <DashboardItem
        name='filecloud'
        icon='cloud download'
        color='yellow'
        label='Databank'
        description='DNA sequences, cell data, etc.'
        countLabel='data packages'
        count={6}
      />
      {/* <DashboardItem
        name='filecloud'
        icon='cloud download'
        color='yellow'
        label='GAP Analysis'
        description='Current state assessment'
        countLabel='...'
        count={6}
      /> */}
      <DashboardItem
        // name='filecloud'
        icon='rss'
        color='orange'
        label='News Aggregation'
        description='Your news distribution channels'
        countLabel='sources'
        count={2}
      />
      <DashboardItem
        // name='filecloud'
        icon='money'
        color='olive'
        label='Fundraising Campaigns, keep donors informed'
        description='Keep track of your fundraisers'
        countLabel='campains'
        count={2}
      />
      {/* <DashboardItem
        // name='filecloud'
        icon='file archive'
        color='olive'
        label='Electronic Medical Records'
        description='Keep track of your fundraisers'
        countLabel='campains'
        count={2}
      /> */}
      <DashboardItem
        // name='filecloud'
        icon='dna'
        color='teal'
        label='Sequencing Service'
        description='Gather sequencing data'
        countLabel='samples'
        count={5}
      />
      {/* <DashboardItem
        // name='filecloud'
        icon='heart'
        color='pink'
        label='Other Resources'
        description='Cell, Animal Models, Labs'
        countLabel='resources'
        count={2}
      /> */}
      <DashboardItem
        // name='filecloud'
        icon='heart'
        color='pink'
        label='Model Organisms'
        description='Mice, Pigs, Flies, Worms, ...'
        countLabel='resources'
        count={2}
      />
      <DashboardItem
        // name='filecloud'
        icon='user md'
        color='red'
        label='Advisors'
        description='Manage external advisors'
        countLabel='advisors'
        count={2}
      />
      <DashboardItem
        // name='filecloud'
        icon='write square'
        color='violet'
        label='Surveys'
        description='Create and manage surveys'
        countLabel='survey'
        count={1}
      />
    </Card.Group>
  </>
}

export default Dashboard