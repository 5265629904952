import { Link, Redirect, RouteComponentProps, Router, useNavigate, WindowLocation } from '@reach/router';
import React from 'react';
import { Button, Checkbox, Form, Header, Icon, Item, Message, Step, TextArea } from 'semantic-ui-react';

interface Props extends RouteComponentProps {
  location?: WindowLocation
}

const FileCloudConsume: React.FunctionComponent<Props> = ({
  location
}) => {
  const section = location?.pathname.split('/').reverse()[0]
  const navigate = useNavigate()
  return <>
    <Header
      // textAlign='center'
      size='large'
      color='blue'
    >
      <Icon name='cloud download' />
      <Header.Content>
        Databank
        <Header.Subheader>Download Center</Header.Subheader>
      </Header.Content>
    </Header>
    <Step.Group ordered fluid>
      <Step
        as={Link}
        to='select'
        active={section === 'select'}
        completed={!!section && section !== 'select'}>
        <Step.Content>
          <Step.Title>Pick Files</Step.Title>
          <Step.Description>Choose the files you wish to download</Step.Description>
        </Step.Content>
      </Step>
      <Step
        as={Link}
        to='request'
        active={section === 'request'}
        completed={section === 'complete'}>
        <Step.Content>
          <Step.Title>Submit Request</Step.Title>
          <Step.Description>Provide your information to submit request</Step.Description>
        </Step.Content>
      </Step>
      <Step
        as={Link}
        to='complete'
        active={section === 'complete'}
        completed={section === 'complete'}>
        <Step.Content>
          <Step.Title>Complete</Step.Title>
          <Step.Description>You will receive an email with a download link</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
    <Router>
      <Redirect from='/' to='select' noThrow />
      <FileCloudConsumeSelect
        path='select'
        onSubmit={() => navigate('/app/filecloud/request')}
      />
      <FileCloudConsumeRequest
        path='request'
        onSubmit={() => navigate('/app/filecloud/complete')}
      />
      <FileCloudConsumeComplete
        path='complete'
      />
    </Router>
  </>
}

interface FileCloudConsumeSelectProps extends RouteComponentProps {
  onSubmit: () => void
}

const FileCloudConsumeSelect: React.FunctionComponent<FileCloudConsumeSelectProps> = ({
  onSubmit
}) => {
  return <>
    <Header
      size='medium'
      content='Available Files'
    />
    <Message info>
      Select the files you want to download.
    </Message>
    <Item.Group>
      <Item>
        <Item.Content>
          <Item.Header>
            <Checkbox label='Whole Genome Tumor, 90x patient xxx' />
          </Item.Header>
          <Item.Meta>
            24GB, download time 5 hours
            </Item.Meta>
          <Item.Extra>
            [Readme]
            </Item.Extra>
        </Item.Content>
      </Item>
    </Item.Group>
    <Button onClick={onSubmit} content='Download' primary />
  </>
}

interface FileCloudConsumeRequestProps extends RouteComponentProps {
  onSubmit: () => void
}

const FileCloudConsumeRequest: React.FunctionComponent<FileCloudConsumeRequestProps> = ({
  onSubmit
}) => {
  return <>
    <Header
      size='medium'
      content='3 Files Selected'
    />
    <Message info>
      Please provide your information so we can prepare personal download links for you.
      </Message>
    <Form onSubmit={onSubmit}>
      <Form.Field>
        <label>First Name</label>
        <input placeholder='First Name' />
      </Form.Field>
      <Form.Field>
        <label>Last Name</label>
        <input placeholder='Last Name' />
      </Form.Field>
      <Form.Field>
        <label>Email</label>
        <input type='email' placeholder='Email' />
      </Form.Field>
      <Form.Field>
        <label>Intended Use</label>
        <TextArea placeholder='Intended Use' />
      </Form.Field>
      <Form.Field>
        <Checkbox label='I agree to the Terms and Conditions' />
      </Form.Field>
      <Button type='submit' content='Request' primary />
    </Form>
  </>
}


interface FileCloudConsumeCompleteProps extends RouteComponentProps {
}

const FileCloudConsumeComplete: React.FunctionComponent<FileCloudConsumeCompleteProps> = () => {
  return <>
    <Header textAlign='center' icon size='medium'>
      <Icon color='blue' name='envelope' />
        Files Requested, Thank You
        <Header.Subheader>
        We're working on prepairing your download. Look out for an email with the personal download links within the next few days.
        </Header.Subheader>
    </Header>
  </>
}

export default FileCloudConsume